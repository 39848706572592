function ts_env(key) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  return process.env[key] ? process.env[key] : defaultValue;
}

export default {
  baseUrl: process.env.VUE_APP_BASEURL,
  env: process.env.NODE_ENV,
  apiBaseUrl: process.env.VUE_APP_SERVER,
  pptViewService: process.env.VUE_APP_PPT_VIEW_SERVICE,
  docPreviewService: process.env.VUE_APP_DOC_PREVIEW_SERVICE,
  tsMpAppUrl: process.env.VUE_APP_TSURL,
  lessonReminderInterval: parseInt(ts_env('VUE_APP_LESSON_REMINDER_INTERVAL', 15))
};