export default {
  computed: {
    device: function device() {
      return 'PC';
    }
  },
  mounted: function mounted() {
    // In order to fix the click on menu on the ios device will trigger the mouseleave bug
    // https://github.com/PanJiaChen/vue-element-admin/issues/1135
    this.fixBugIniOS();
  },
  methods: {
    fixBugIniOS: function fixBugIniOS() {
      var _this = this;

      var $subMenu = this.$refs.subMenu;

      if ($subMenu) {
        var handleMouseleave = $subMenu.handleMouseleave;

        $subMenu.handleMouseleave = function (e) {
          if (_this.device === 'mobile') {
            return;
          }

          handleMouseleave(e);
        };
      }
    }
  }
};