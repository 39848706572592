/**
 * http请求服务
 */
import { fetch } from "./httpService";
import config from "./config";
var baseUrl = config.baseUrl + '/api';
export var commonAPI = {
  getLoginWxQRCode: function getLoginWxQRCode(params) {
    return fetch(baseUrl + "/wechat/login/wx_qr_code", params, "POST");
  },
  getBindWxQRCode: function getBindWxQRCode(params) {
    return fetch(baseUrl + "/wechat/bind/wx_qr_code", params, "POST");
  },
  qr_check: function qr_check(params) {
    return fetch(baseUrl + "/wechat/qr_check", params, "POST");
  }
};