var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper clearfix", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _c("div", { staticClass: "fixedHeader" }, [_c("navbar")], 1),
          _c("app-main", {
            directives: [
              {
                name: "customLoading",
                rawName: "v-customLoading",
                value: _vm.sidebar.customLoading,
                expression: "sidebar.customLoading"
              }
            ]
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }