import "core-js/modules/es6.function.name";
import { AppConfig } from "@/constants/config";
var version_code = 1001023;
import { all_permission } from "./permission";
/** 任务状态值 **/
// 空全部，0待分配，2未开始，3进行中，5待审核，6未通过，7已完成

var taskState = {
  // 待分配
  WAIT_ASSIGNED: 0,
  // 已分配 1 （弃用）
  // 未开始
  NOT_STARTED: 2,
  // 进行中
  IN_PROGRESS: 3,
  // 待审核
  WAIT_CONFIRM: 5,
  // 未通过
  NOT_PASS: 6,
  // 已完成
  COMPLETED: 7,
  // 个性化
  NOT_APPLICABLE: 100
};
var prepareState = {
  // 未教研
  NOT_TEACHING_RESEARCH: 1,
  // 无课件
  NOT_COURSEWARE: 2,
  // 无讲义
  NOT_HANDOUTS: 3,
  // 未备课
  NOT_PREPARE: 4,
  // 已备课
  PREPARE: 5,
  // 完成课前考
  PASS_PREPARE: 6
};
var tag_type = {
  TAG_TYPE_NORMAL: "1",
  TAG_TYPE_KEY: "2"
};
var task_type_code = {
  single_select: {
    type: '1',
    name: '单选',
    component: 'SelectTask'
  },
  multi_select: {
    type: '2',
    name: '多选',
    component: 'SelectTask'
  },
  complete: {
    type: '3',
    name: '填空',
    component: 'Completion'
  },
  true_or_false: {
    type: '4',
    name: '判断',
    component: 'TrueOrFalse'
  },
  essay_jianda: {
    type: '5',
    name: '简答',
    component: 'Essays'
  },
  essay_jisuan: {
    type: '51',
    name: '计算',
    component: 'Essays'
  },
  essay_caozuo: {
    type: '52',
    name: '操作',
    component: 'Essays'
  },
  essay_yingyong: {
    type: '53',
    name: '应用',
    component: 'Essays'
  },
  essay_zuotu: {
    type: '54',
    name: '作图',
    component: 'Essays'
  },
  essay_zuowen: {
    type: '6',
    name: '作文',
    component: 'Essays'
  },
  // lianxian: {type: '7', name: '连线', component: 'Completion'},
  comprehensive_english_read: {
    type: '8',
    name: '英语阅读',
    component: 'Comprehensive'
  },
  comprehensive_english_complete: {
    type: '9',
    name: '英语完形',
    component: 'Comprehensive'
  },
  comprehensive: {
    type: '10',
    name: '综合',
    component: 'Comprehensive'
  }
};
export var constants = {
  appConfig: AppConfig,
  key: {
    token: 'token'
  },
  version_code: version_code,
  divide_tags: '\x7F',
  divide_tags_new: '\x7F,',
  divide_analyze_task_number: ',',
  API_RESULT_OK: 1,
  permission: all_permission,
  tag_type: tag_type,

  /** 试题类型 **/
  task_type_code: task_type_code,
  task_type: [{
    type: task_type_code.single_select.type,
    name: task_type_code.single_select.name
  }, {
    type: task_type_code.multi_select.type,
    name: task_type_code.multi_select.name
  }, {
    type: task_type_code.complete.type,
    name: task_type_code.complete.name
  }, {
    type: task_type_code.true_or_false.type,
    name: task_type_code.true_or_false.name
  }, {
    type: task_type_code.essay_jianda.type,
    name: task_type_code.essay_jianda.name
  }, {
    type: task_type_code.essay_zuowen.type,
    name: task_type_code.essay_zuowen.name
  }, {
    type: task_type_code.essay_caozuo.type,
    name: task_type_code.essay_caozuo.name
  }, {
    type: task_type_code.essay_jisuan.type,
    name: task_type_code.essay_jisuan.name
  }, {
    type: task_type_code.essay_yingyong.type,
    name: task_type_code.essay_yingyong.name
  }, {
    type: task_type_code.essay_zuotu.type,
    name: task_type_code.essay_zuotu.name
  }, // {
  //   type: task_type_code.lianxian.type,
  //   name: task_type_code.lianxian.name,
  // },
  {
    type: task_type_code.comprehensive_english_read.type,
    name: task_type_code.comprehensive_english_read.name
  }, {
    type: task_type_code.comprehensive_english_complete.type,
    name: task_type_code.comprehensive_english_complete.name
  }, {
    type: task_type_code.comprehensive.type,
    name: task_type_code.comprehensive.name
  }],
  taskState: taskState,
  taskStatus: {
    // 待审核
    WAIT_CHECK: 0,
    // 已审核
    CHECKED: 1,
    // 已拒绝
    REJECTED: 2
  },

  /** 任务状态信息 **/
  taskStateInfos: [{
    id: '',
    type: 'info',
    name: '全部'
  }, {
    id: taskState.WAIT_ASSIGNED,
    type: '',
    name: '待分配'
  }, {
    id: taskState.NOT_STARTED,
    type: 'info',
    name: '未开始'
  }, {
    id: taskState.IN_PROGRESS,
    type: 'danger',
    name: '进行中'
  }, {
    id: taskState.WAIT_CONFIRM,
    type: 'warning',
    name: '待审核'
  }, {
    id: taskState.NOT_PASS,
    type: 'danger',
    name: '未通过'
  }, {
    id: taskState.COMPLETED,
    type: 'success',
    name: '已完成'
  } // {
  //   id: taskState.NOT_APPLICABLE,
  //   type: 'info',
  //   name: '无工作流'
  // },
  ],

  /**通知顶层类型**/
  event_top_type: {
    // 讲义任务
    JIANGYI: 1,
    // 课件任务
    KEJIAN: 2,
    // 试题
    SHITI: 3,
    // 试卷
    SHIJUAN: 4,
    // 资料
    ZILIAO: 5,
    // 上课反思
    KECHENG_FANSI: 6
  },
  prepareState: prepareState,

  /** 备课页面的 教研状态 (临时) **/
  prepareStatusInfos: [{
    id: '',
    type: 'info',
    name: '全部'
  }, {
    id: prepareState.NOT_TEACHING_RESEARCH,
    type: 'danger',
    name: '未教研'
  }, {
    id: prepareState.NOT_COURSEWARE,
    type: 'warning',
    name: '无课件'
  }, {
    id: prepareState.NOT_HANDOUTS,
    type: 'warning',
    name: '无讲义'
  }, {
    id: prepareState.NOT_PREPARE,
    type: '',
    name: '未备课'
  }, {
    id: prepareState.PASS_PREPARE,
    type: 'success',
    name: '完成课前考'
  }],

  /** 定义讲义详情页面和课件详情界面的 **/
  DETAIL_PAGE: {
    // 编辑
    ACTION_EDIT: '1',
    // 查看 view
    ACTION_VIEW: '2',
    // 审核
    ACTION_CHECK: '3'
  },
  MAX_VIDEO_PER_WARE: 3,
  pickerOptions: {
    shortcuts: [{
      text: '最近一周',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一年',
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        picker.$emit('pick', [start, end]);
      }
    }]
  },
  uploadFileTypeList: [{
    ext: 'doc,docx',
    path: require("../images/icon_filetype_word.png")
  }, {
    ext: 'jpeg,png,jpg',
    path: require("../images/icon_filetype_image.png")
  }, {
    ext: 'pdf',
    path: require("../images/icon_filetype_pdf.png")
  }, {
    ext: '',
    path: require("../images/icon_filetype_unkonw.png")
  }, {
    ext: 'ppt,pptx',
    path: require("../images/icon_filetype_ppt.png")
  }, {
    ext: 'txt',
    path: require("../images/icon_filetype_txt.png")
  }, {
    ext: 'xls,xlsx',
    path: require("../images/icon_filetype_excel.png")
  }, {
    ext: 'zip,rar',
    path: require("../images/icon_filetype_zip.png")
  }, {
    ext: 'mp4',
    path: require("../images/icon_filetype_video.png")
  }, {
    ext: 'mp3',
    path: require("../images/icon_filetype_audio.png")
  }],
  answer_sheet_option: {
    DEFAULT: 'default',
    CUSTOMIZED: 'customized',
    GRIDPAPER: 'gridpaper-800',
    UNDERSCORES: 'underscores-1p'
  }
};