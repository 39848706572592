//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import logo from '@/images/logo_mini.png';
import logo2 from '@/images/icon_logo.png';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '',
      logo: logo,
      logo2: logo2
    };
  }
};