import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/Users/gsy/work_out/ts_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.regexp.split";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import BottomInfo from "@/module/components/BottomInfo";
export default {
  components: {
    BottomInfo: BottomInfo,
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      routes: []
    };
  },
  mounted: function mounted() {
    var _this = this;

    var routers = this.$router.options.routes;

    var _loop = function _loop(per) {
      var item = _this.constants.permission[per];

      if (item.path === undefined || item.path === null) {
        return "continue";
      } // 有该权限则不处理


      if (_this.utils.checkPermission(item)) {
        return "continue";
      }

      var paths = item.path.split('/'); // 第一级权限需要特殊处理

      if (paths.length === 1) {
        routers.splice(routers.findIndex(function (r) {
          return r.path.replace("/", '') === paths[0];
        }), 1);
        return "continue";
      }

      var route = routers.find(function (r) {
        return r.path.replace("/", '') === paths[0];
      }); // 找到要删除的路由的上一级路由

      var _loop2 = function _loop2(i) {
        if (route === undefined || route === null) {
          return "break";
        }

        route = route.children.find(function (r) {
          return r.path.replace("/", '') === paths[i];
        });
      };

      for (var i = 1; i < paths.length - 1; i++) {
        var _ret2 = _loop2(i);

        if (_ret2 === "break") break;
      }

      if (route === undefined || route === null) {
        return "continue";
      }

      route.children.splice(route.children.findIndex(function (r) {
        return r.path.replace("/", '') === paths[paths.length - 1];
      }), 1);
    };

    for (var per in this.constants.permission) {
      var _ret = _loop(per);

      if (_ret === "continue") continue;
    }

    this.routes = routers;
  },
  computed: _objectSpread({}, mapGetters(['sidebar']), {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path;
      return path;
    },
    showLogo: function showLogo() {
      return true;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};