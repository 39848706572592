var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-wrapper" },
    [
      _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
      (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
      !_vm.item.alwaysShow
        ? [
            _vm.onlyOneChild.meta
              ? _c(
                  "app-link",
                  { attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) } },
                  [
                    _c(
                      "el-menu-item",
                      {
                        class: { "submenu-title-noDropdown": !_vm.isNest },
                        attrs: { index: _vm.resolvePath(_vm.onlyOneChild.path) }
                      },
                      [
                        _vm.onlyOneChild.meta.icon && _vm.onlyOneChild.meta.svg
                          ? _c("svg-icon", {
                              staticClass: "iconClass",
                              attrs: {
                                iconClass:
                                  _vm.onlyOneChild.meta.icon ||
                                  (_vm.item.meta && _vm.item.meta.icon)
                              }
                            })
                          : _vm._e(),
                        _vm.onlyOneChild.meta.icon && !_vm.onlyOneChild.meta.svg
                          ? _c("i", {
                              staticClass: "iconClass",
                              class:
                                _vm.onlyOneChild.meta.icon ||
                                (_vm.item.meta && _vm.item.meta.icon)
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(_vm._s(_vm.onlyOneChild.meta.title))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _c(
            "el-submenu",
            {
              ref: "subMenu",
              attrs: {
                index: _vm.resolvePath(_vm.item.path),
                "popper-append-to-body": ""
              }
            },
            [
              _c(
                "template",
                { slot: "title" },
                [
                  _vm.item.meta.svg
                    ? _c("svg-icon", {
                        staticClass: "iconClass",
                        attrs: { iconClass: _vm.item.meta.icon }
                      })
                    : _c("i", {
                        staticClass: "iconClass",
                        class: _vm.item.meta.icon
                      }),
                  _c("span", [_vm._v(_vm._s(_vm.item.meta.title))])
                ],
                1
              ),
              _vm._l(_vm.item.children, function(child) {
                return _c("sidebar-item", {
                  key: child.path,
                  staticClass: "nest-menu",
                  attrs: {
                    "is-nest": true,
                    item: child,
                    "base-path": _vm.resolvePath(child.path)
                  }
                })
              })
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }