var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "info" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showScreen,
            expression: "showScreen"
          }
        ],
        staticClass: "content-wrapper",
        on: { mouseleave: _vm.onMouseOut }
      },
      [
        _c("div", { staticClass: "content-logo" }, [
          _vm._m(0),
          _c(
            "div",
            [
              _c(
                "el-tooltip",
                { attrs: { effect: "light", placement: "top" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("北京市海淀区苏州街3号504-31")
                  ]),
                  _c("i", { staticClass: "el-icon-map-location" })
                ]
              ),
              _c(
                "el-tooltip",
                { attrs: { effect: "light", placement: "top" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("zhixiaoyoufang@163.com")
                  ]),
                  _c("i", { staticClass: "el-icon-message" })
                ]
              ),
              _c(
                "el-tooltip",
                { attrs: { effect: "light", placement: "top" } },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("关注公众号:智校有方")
                  ]),
                  _c("i", { staticClass: "el-icon-chat-dot-round" })
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "content-help" }, [
          _c(
            "a",
            {
              staticClass: "title",
              attrs: { href: _vm.docUrl, target: "_blank" }
            },
            [_vm._v("使用帮助")]
          )
        ]),
        _c("div", { staticClass: "content-title" }, [
          _c("div", { staticClass: "title" }, [_vm._v("智校有方产品")]),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.docUrl + "4教研管理/教研管理.html",
                  target: "_blank"
                }
              },
              [_vm._v("教研和教学系统")]
            )
          ]),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.docUrl + "6资料管理/在线卷.html",
                  target: "_blank"
                }
              },
              [_vm._v("作业系统")]
            )
          ])
        ]),
        _c("div", { staticClass: "content-title" }, [
          _c("div", [
            _c(
              "a",
              {
                staticClass: "title",
                attrs: {
                  href: _vm.docUrl + "8用户设置/用户设置.html",
                  target: "_blank"
                }
              },
              [_vm._v("关于我们")]
            )
          ]),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.docUrl + "8用户设置/用户设置.html",
                  target: "_blank"
                }
              },
              [_vm._v("公司介绍")]
            )
          ]),
          _c("div", [_vm._v("联系方式")])
        ]),
        _vm._m(1),
        _vm._m(2)
      ]
    ),
    _c(
      "div",
      { staticClass: "net-info", on: { mouseenter: _vm.onMouseOver } },
      [
        _c("a", { attrs: { href: _vm.ICPUrl, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.ICPNumber))
        ]),
        _c("div", { staticStyle: { "padding-left": "10px" } }, [
          _vm._v("智校有方")
        ]),
        _c("div", { staticClass: "net-info-divider" }, [_vm._v("|")]),
        _c("div", [_vm._v("@zhixiaoyoufang.com")]),
        _c("div", [_vm._v(_vm._s(_vm.getVersion))])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticStyle: { background: "white", width: "200px" },
        attrs: { src: require("../images/icon_logo_demo.png") }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-qrcode" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("../images/public_qrcode_logo.jpg") }
        })
      ]),
      _c("div", [
        _c("div", [_vm._v("智校有方")]),
        _c("div", [_vm._v("作业小程序")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-qrcode" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("../images/public_qrcode_logo.jpg") }
        })
      ]),
      _c("div", [
        _c("div", [_vm._v("智校有方")]),
        _c("div", [_vm._v("官方公众号")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }