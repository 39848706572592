/**
 * http请求服务
 */
import { fetch } from "./httpService";
import config from "./config";
import { commonAPI } from "./api_common";
var baseUrl = config.baseUrl + '/api';
export var systemAPI = {
  commonAPI: commonAPI,

  /**
   * 修改密码
   */
  updatePassword: function updatePassword(params) {
    return fetch(baseUrl + "/user/change_password", params, "POST");
  },

  /**
   * 资料编辑
   */
  stuffUpdate: function stuffUpdate(params) {
    return fetch(baseUrl + "/stuff/update", params, "POST");
  },

  /**
   * 资料删除
   */
  stuffDelete: function stuffDelete(params) {
    return fetch(baseUrl + "/stuff/delete", params, "POST");
  },

  /**
   * 资料解绑
   */
  stuffUnbind: function stuffUnbind(params) {
    return fetch(baseUrl + "/stuff/unbind", params, "POST");
  },

  /**
   * 资料绑定
   */
  stuffBind: function stuffBind(params) {
    return fetch(baseUrl + "/stuff/bind", params, "POST");
  },

  /**
   * 课次列表
   */
  timesList: function timesList(params) {
    return fetch(baseUrl + "/times/list", params, "POST");
  },

  /**
   * 课次详情
   */
  timesDetail: function timesDetail(params) {
    return fetch(baseUrl + "/times/detail", params, "POST");
  },

  /**
   * 根据ids返回资料信息
   */
  stuffList: function stuffList(params) {
    return fetch(baseUrl + "/stuff/list", params, "POST");
  },

  /**
   * 创建反思
   */
  addreflect: function addreflect(params) {
    return fetch(baseUrl + "/reflect/create", params, "POST");
  },

  /**
   * 提交老师
   */
  submitTeachers: function submitTeachers(params) {
    return fetch(baseUrl + "/common/rteacher", params, "POST");
  },

  /**
   * 反思模板
   */
  reflectTemplate: function reflectTemplate(params) {
    return fetch(baseUrl + "/reflect/template", params, "POST");
  },

  /**
   * 资料搜索
   */
  auditStuff: function auditStuff(params) {
    return fetch(baseUrl + "/stuff/check", params, "POST");
  },

  /**
   * 资料搜索
   */
  dataSearch: function dataSearch(params) {
    return fetch(baseUrl + "/stuff/search", params, "POST");
  },

  /**
   * 回复添加
   */
  addReply: function addReply(params) {
    return fetch(baseUrl + "/reflect/reply", params, "POST");
  },

  /**
   * 回复删除
   */
  deleteReply: function deleteReply(params) {
    return fetch(baseUrl + "/reflect/reply_delete", params, "POST");
  },

  /**
   * 编辑回复
   */
  updateReply: function updateReply(params) {
    return fetch(baseUrl + "/reflect/reply_edit", params, "POST");
  },

  /**
   * 反思详情
   */
  considerDetail: function considerDetail(params) {
    return fetch(baseUrl + "/reflect/detail", params, "POST");
  },

  /**
   * 反思列表
   */
  considerList: function considerList(params) {
    return fetch(baseUrl + "/reflect/list", params, "POST");
  },

  /**
   * 讲义的下载
   */
  handoutsDownloadUrl: function handoutsDownloadUrl(params) {
    return "".concat(baseUrl, "/lecture/download/?id=").concat(params['id'], "&token=").concat(params['token']);
  },

  /**
   * 讲义的编辑
   */
  handoutsUpdate: function handoutsUpdate(params) {
    return fetch(baseUrl + "/lecture/update", params, "POST");
  },

  /**
  * 讲义的创建
  */
  handoutsCreate: function handoutsCreate(params) {
    return fetch(baseUrl + "/lecture/create", params, "POST");
  },

  /**
   * 我的个性化讲义
   */
  myCustomizedHandout: function myCustomizedHandout(params) {
    return fetch(baseUrl + "/lecture/my_customized", params, "POST");
  },

  /**
   * 讲义的创建
   */
  wareCreate: function wareCreate(params) {
    return fetch(baseUrl + "/ware/create", params, "POST");
  },

  /**
   * 我的个性化课件
   */
  myCustomizedWare: function myCustomizedWare(params) {
    return fetch(baseUrl + "/ware/my_customized", params, "POST");
  },

  /**
   * 课件的编辑
   */
  wareUpdate: function wareUpdate(params) {
    return fetch(baseUrl + "/ware/update", params, "POST", true);
  },
  wareUpdateUrl: baseUrl + "/ware/update",

  /**
   * 添加课件备注
   */
  addRemark: function addRemark(params) {
    return fetch(baseUrl + '/ware/remark_add', params, "POST");
  },

  /**
   * 删除课件备注
   */
  deleteRemark: function deleteRemark(params) {
    return fetch(baseUrl + '/ware/remark_delete', params, "DELETE");
  },

  /**
   * 编辑课件备注
   */
  updateRemark: function updateRemark(params) {
    return fetch(baseUrl + '/ware/remark_edit', params, "POST");
  },

  /**
   * 添加课件备注
   */
  addPrepareRemark: function addPrepareRemark(params) {
    return fetch(baseUrl + '/prepare/remark_add', params, "POST");
  },

  /**
   * 删除课件备注
   */
  deletePrepareRemark: function deletePrepareRemark(params) {
    return fetch(baseUrl + '/prepare/remark_delete', params, "DELETE");
  },

  /**
   * 编辑课件备注
   */
  updatePrepareRemark: function updatePrepareRemark(params) {
    return fetch(baseUrl + '/prepare/remark_edit', params, "POST");
  },

  /**
   * 备课列表
   */
  prepareTimes: function prepareTimes(params) {
    return fetch(baseUrl + "/prepare/times", params, "POST");
  },

  /**
   * 登陆轮播图
   */
  swiperImg: function swiperImg() {
    return fetch(baseUrl + "/config/lbanner", {}, "POST");
  },

  /**
   * 修改用户信息
   */
  userUpdate: function userUpdate(params) {
    return fetch(baseUrl + "/user/update", params, "POST", true);
  },

  /**
   * 讲义任务审核
   */
  auditHandouts: function auditHandouts(params) {
    return fetch(baseUrl + "/lecture/check", params, "POST");
  },

  /**
   * 课件任务审核
   */
  auditWares: function auditWares(params) {
    return fetch(baseUrl + "/ware/check", params, "POST");
  },

  /**
   * 讲义任务分配
   */
  assignHandouts: function assignHandouts(params) {
    return fetch(baseUrl + "/lecture/distribute", params, "POST");
  },

  /**
   * 课件任务分配
   */
  assignWare: function assignWare(params) {
    return fetch(baseUrl + "/ware/distribute", params, "POST");
  },

  /**
   * 干活老师
   */
  workTeachers: function workTeachers(params) {
    return fetch(baseUrl + "/common/workteacher", params, "GET");
  },

  /**
   * 审核课件老师
   */
  checkTeachers: function checkTeachers(params) {
    return fetch(baseUrl + "/common/checkteacher", params, "GET");
  },

  /**
   * 首页我的事件
   */
  indexMyEvents: function indexMyEvents(params) {
    return fetch(baseUrl + "/teacherevent/list", params, "POST");
  },

  /**
   * 首页我的事件
   */
  indexEventsClose: function indexEventsClose(params) {
    return fetch(baseUrl + "/teacherevent/close", params, "POST");
  },

  /**
   * 首页我的课件
   */
  indexMyWares: function indexMyWares(params) {
    return fetch(baseUrl + "/index/ware", params, "POST");
  },

  /**
   * 首页我的讲义
   */
  indexMyHandouts: function indexMyHandouts(params) {
    return fetch(baseUrl + "/index/lecture", params, "POST");
  },

  /**
   * 首页我的课程
   */
  indexMyCourse: function indexMyCourse(params) {
    return fetch(baseUrl + "/index/times", params, "POST");
  },

  /**
   * 课程列表
   */
  courseList: function courseList(params) {
    return fetch(baseUrl + "/course/search", params, "POST");
  },

  /**
   * 课件列表
   */
  coursewareList: function coursewareList(params) {
    return fetch(baseUrl + "/ware/search", params, "POST");
  },

  /**
   * 讲义列表
   */
  handoutsList: function handoutsList(params) {
    return fetch(baseUrl + "/lecture/search", params, "POST");
  },

  /**
   * 一个课程的讲义列表
   */
  courseHandoutsList: function courseHandoutsList(id) {
    return fetch(baseUrl + "/course/lectureall", {
      course_id: id
    }, "POST");
  },

  /**
   * 一个课程的
   * 课件列表
   */
  courseWareList: function courseWareList(id) {
    return fetch(baseUrl + "/course/wareall", {
      course_id: id
    }, "POST");
  },

  /**
   * 讲义详情
   */
  handoutsDetail: function handoutsDetail(params) {
    return fetch(baseUrl + "/lecture/detail", params, "POST");
  },

  /**
   * 课件详情
   */
  coursewareDetail: function coursewareDetail(params) {
    return fetch(baseUrl + "/ware/detail", params, "POST");
  },

  /**
   * 验证码获取
   */
  getCaptcha: function getCaptcha() {
    return fetch(baseUrl + "/common/captcha", {}, "GET");
  },

  /**
   * 讲义字典
   */
  handoutsDict: function handoutsDict(params) {
    return fetch(baseUrl + "/common/power", params, "GET");
  },

  /**
   *登录
   */
  login: function login(params) {
    return fetch(baseUrl + "/portal/login", params, "POST");
  },

  /**
   *用户信息
   */
  userInfo: function userInfo() {
    return fetch(baseUrl + "/user/info", {}, "POST");
  },

  /**
   * 添加试题
   */
  addTask: function addTask(params) {
    return fetch(baseUrl + "/question/add", params, "POST");
  },

  /**
   * 查询tag
   */
  queryTag: function queryTag(params) {
    return fetch(baseUrl + "/question/tags", params, "POST");
  },

  /**
   * 试题列表
   */
  getTaskList: function getTaskList(params) {
    return fetch(baseUrl + "/question/list", params, "POST");
  },

  /**
   * 获取备课测试的题目，返回5条
   */
  getQuestionListForTest: function getQuestionListForTest(params) {
    return fetch(baseUrl + "/question/list_for_times", params, "POST");
  },

  /**
   * 试题路径
   */
  getTaskPath: function getTaskPath(params) {
    return fetch(baseUrl + "/question/exampath", params, "POST");
  },
  searchExamPath: function searchExamPath(params) {
    return fetch(baseUrl + "/question/search_exampath", params, "POST");
  },

  /**
   * 试题收藏
   */
  taskFavorite: function taskFavorite(params) {
    return fetch(baseUrl + "/question/favorite", params, "POST");
  },

  /**
   * 试题审核
   */
  questionCheck: function questionCheck(params) {
    return fetch(baseUrl + "/question/check", params, "POST");
  },

  /**
   * 试题修改类型
   */
  questionTypeChange: function questionTypeChange(params) {
    return fetch(baseUrl + "/question/change_type", params, "POST");
  },

  /**
   * 试题错误提交
   */
  submitTaskError: function submitTaskError(params) {
    return fetch(baseUrl + "/question/feedback", params, "POST");
  },

  /**
   * 试题错误处理
   */
  questionError: function questionError(params) {
    return fetch(baseUrl + "/question/error_update", params, "POST");
  },

  /**
   * 试题详情
   */
  getQuestionDetail: function getQuestionDetail(params) {
    return fetch(baseUrl + "/question/detail", params, "POST");
  },

  /**
   * 试题删除
   */
  questionDelete: function questionDelete(params) {
    return fetch(baseUrl + "/question/delete", params, "POST");
  },
  getSubTypeList: function getSubTypeList(params) {
    return fetch(baseUrl + "/question/sub_type_list", params, "POST");
  },

  /**
   * 试卷审核
   */
  paperCheck: function paperCheck(params) {
    return fetch(baseUrl + "/paper/check", params, "POST");
  },

  /**
   * 试卷送审
   */
  paperForReview: function paperForReview(params) {
    return fetch(baseUrl + "/paper/public", params, "POST");
  },

  /**
   * 保存组卷
   */
  savePaper: function savePaper(params) {
    if (params.id === null || params.id === 'null' || params.id === undefined || params.id === "") {
      return fetch(baseUrl + "/paper/create", params, "POST");
    }

    return fetch(baseUrl + "/paper/update", params, "POST");
  },

  /**
   * 请求数据
   */
  requestPaper: function requestPaper(params) {
    return fetch(baseUrl + "/paper/detail", params, "POST");
  },

  /**
   * 请求试卷列表
   */
  getPaperList: function getPaperList(params) {
    return fetch(baseUrl + "/paper/list", params, "POST");
  },
  getPaperListAll: function getPaperListAll(params) {
    return fetch(baseUrl + "/paper/list_all", params, "POST");
  },

  /**
   * 获取一个老师所有下属的统计数据
   */
  getDataStatistics: function getDataStatistics(params) {
    return fetch(baseUrl + "/statistics/list", params, "POST");
  },

  /**
   * 获取一个老师的统计数据
   */
  getDataStatisticsById: function getDataStatisticsById(params) {
    return fetch(baseUrl + "/statistics/detail", params, "POST");
  },

  /**
   * 上课记录列表
   */
  lessonList: function lessonList(params) {
    return fetch(baseUrl + "/lesson/list", params, "POST");
  },

  /**
   * 上课
   */
  classBegin: function classBegin(params) {
    return fetch(baseUrl + "/times/classbegin", params, "POST");
  },

  /**
   * 下课
   */
  classOver: function classOver(params) {
    return fetch(baseUrl + "/times/classover", params, "POST");
  },
  searchTimes: function searchTimes(params) {
    return fetch(baseUrl + "/times/search", params, "POST");
  },

  /**
   * 备课完成
   */
  prepareComplete: function prepareComplete(params) {
    return fetch(baseUrl + "/prepare/complete", params, "POST");
  },
  wordToHtmlUrl: baseUrl + '/editor/wordtohtml',
  getOssPostPolicy: function getOssPostPolicy(params) {
    return fetch(baseUrl + '/common/getosspostpolicy', params, 'POST');
  },
  recordFileUpload: function recordFileUpload(params) {
    return fetch(baseUrl + "/stuff/create", params, 'POST');
  },
  deleteStuff: function deleteStuff(params) {
    return fetch(baseUrl + "/stuff/delete", params, 'POST');
  },

  /**
   * 在线卷
   */
  getPaperOnline: function getPaperOnline(params) {
    return fetch(baseUrl + "/paperonline/detail", params, "POST");
  },

  /**
   * 在线卷列表
   */
  getPaperOnlineList: function getPaperOnlineList(params) {
    return fetch(baseUrl + "/paperonline/list", params, "POST");
  },

  /**
   * 创建在线卷
   */
  createOnlinePaper: function createOnlinePaper(params) {
    return fetch(baseUrl + "/paperonline/create", params, "POST");
  },

  /**
   * 生成在线作业
   */
  createOnlineWork: function createOnlineWork(params) {
    return fetch(baseUrl + "/paperonline/creatework", params, "POST");
  },

  /**
   * 更新在线卷
   */
  updateOnlineAnswer: function updateOnlineAnswer(params) {
    return fetch(baseUrl + "/paperonlineanswer/update", params, "POST");
  },

  /**
   * 获取某个学生的作答结果
   */
  getPaperOnlineAnswer: function getPaperOnlineAnswer(params) {
    return fetch(baseUrl + "/paperonlineanswer/detail", params, "POST");
  },

  /**
   * 根据id获取在线卷作答结果
   */
  getPaperOnlineAnswerWithId: function getPaperOnlineAnswerWithId(params) {
    return fetch(baseUrl + "/paperonlineanswer/detailwithid", params, "POST");
  },

  /**
   * 获取在线卷的所有答题结果
   */
  getPaperOnlineAnswerList: function getPaperOnlineAnswerList(params) {
    return fetch(baseUrl + "/paperonlineanswer/answer_list", params, "POST");
  },

  /**
   * 根据用户信息生成jwt，同时开始答题
   */
  getJwt: function getJwt(params) {
    return fetch(baseUrl + "/paperonlineanswer/jwt", params, "POST");
  },

  /**
   * 获取备课测试题
   */
  getPrepareLessonTestQuestion: function getPrepareLessonTestQuestion(params) {
    return fetch(baseUrl + "/question/list_for_times", params, "POST");
  },

  /**
   * 获取所有备课测试题
   */
  getPrepareLessonTestQuestionAll: function getPrepareLessonTestQuestionAll(params) {
    return fetch(baseUrl + "/question/list_for_test", params, "POST");
  },

  /**
   * 解除备课测试的试题和课次的绑定
   */
  unbindTestQuestion: function unbindTestQuestion(params) {
    return fetch(baseUrl + "/question/unbind_times", params, "POST");
  },

  /**
   * 绑定试题到课次上
   */
  bindTestQuestion: function bindTestQuestion(params) {
    return fetch(baseUrl + "/question/bind_times", params, "POST");
  },

  /**
   * 提交备课测试
   */
  submitPrepareLessonTest: function submitPrepareLessonTest(params) {
    return fetch(baseUrl + "/question/submit_prepare_test", params, "POST");
  },
  getAdsByCourseId: function getAdsByCourseId(params) {
    return fetch(baseUrl + "/ads/recession", params, "GET");
  },
  getAdsByTeacherId: function getAdsByTeacherId(params) {
    return fetch(baseUrl + "/ads/byteacher", params, "GET");
  },
  editorImageUploadUrl: function editorImageUploadUrl() {
    return baseUrl + "/editor/imageUpload?token=" + window.localStorage.getItem('token');
  },
  getPopupList: function getPopupList(params) {
    return fetch(baseUrl + "/popup/list", params, "GET");
  },

  /**
   * 师训-管理
   * @param params
   * @returns {Promise}
   */
  getSxList: function getSxList(params) {
    return fetch(baseUrl + "/api/admin/sx/list", params, "GET");
  },
  deleteSx: function deleteSx(params) {
    return fetch(baseUrl + "/api/admin/sx/delete", params, "POST");
  },
  createSx: function createSx(params) {
    return fetch(baseUrl + "/api/admin/sx/create", params, "POST");
  },
  editSx: function editSx(params) {
    return fetch(baseUrl + "/api/admin/sx/edit", params, "POST");
  },
  sxDelVideo: function sxDelVideo(params) {
    return fetch(baseUrl + "/api/admin/sx/delete/video", params, "POST");
  },

  /**
   * 公共接口
   */
  getCommonTeachers: function getCommonTeachers(params) {
    return fetch(baseUrl + "/api/teacher-list", params, "GET");
  },
  getCommonTeacherPower: function getCommonTeacherPower(params) {
    return fetch(baseUrl + "/api/teacher-power", params, "GET");
  },

  /**
   * 师训列表-前端
   * @param params
   * @returns {Promise}
   */
  getWebSxList: function getWebSxList(params) {
    return fetch(baseUrl + "/api/sx-list", params, "GET");
  },
  reportSxLPlay: function reportSxLPlay(params) {
    return fetch(baseUrl + "/api/sx-report", params, "POST");
  },
  reportSxList: function reportSxList(params) {
    return fetch(baseUrl + "/api/sx-report-list", params, "GET");
  }
};