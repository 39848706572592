import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.regexp.replace";
import "/Users/gsy/work_out/ts_web/node_modules/core-js/modules/es6.array.iterator.js";
import "/Users/gsy/work_out/ts_web/node_modules/core-js/modules/es6.promise.js";
import "/Users/gsy/work_out/ts_web/node_modules/core-js/modules/es6.object.assign.js";
import "/Users/gsy/work_out/ts_web/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import App from "./App.vue";
import "babel-polyfill";
import router from "./router";
import "./icons"; // icon

import store from "./store/index";
import ElementUI from 'element-ui';
import LoadScript from 'vue-plugin-load-script';
import VueMq from 'vue-mq';
import 'element-ui/lib/theme-chalk/index.css';
import "./style/resize.css";
import "./style/transition.less";
import "./style/animate.css";
import "./views/teachingResearch/handouts/index.less";
import "./style/loading.css";
import { utils } from "./utils/utils";
import config from "./service/config";
import { constants } from "./constants/constants";
import Watermark from "./utils/watermark";
import { systemAPI } from "./service/api";
export function openInNewTab(route) {
  var url = this.$router.resolve(route).href;
  window.open(url, '_blank');
}
Vue.prototype.utils = utils;
Vue.prototype.tsConfig = config;
Vue.prototype.constants = constants;
Vue.prototype.api = systemAPI;
Vue.prototype.openInNewTab = openInNewTab;
Vue.directive('customLoading', {
  bind: function bind(el, binding) {
    var tempDiv = document.createElement('div');
    tempDiv.className = 'sk-wave-modal';
    var tempDiv1 = document.createElement('div');
    tempDiv1.className = 'sk-wave';
    tempDiv1.innerHTML = " <div class='sk-rect sk-rect-1'></div>" + "<div class='sk-rect sk-rect-2'></div>" + "<div class='sk-rect sk-rect-3'></div>" + "<div class='sk-rect sk-rect-4'></div>" + "<div class='sk-rect sk-rect-5'></div>";
    tempDiv.style.zIndex = 9999;
    tempDiv.appendChild(tempDiv1);
    el.loadingElement = tempDiv;

    if (binding.value) {
      el.appendChild(tempDiv);
    }
  },
  update: function update(el, binding) {
    if (binding.value) {
      if (el.loadingElement.parentNode === null) {
        el.appendChild(el.loadingElement);
      }
    } else {
      if (el === el.loadingElement.parentNode) {
        el.removeChild(el.loadingElement);
      }
    }
  },
  unbind: function unbind(el) {
    if (el.loadingElement.parentNode === el) {
      el.removeChild(el.loadingElement);
    }

    el.loadingElement = null;
  }
});
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(LoadScript);
Vue.use(VueMq, {
  breakpoints: {
    phone: 450,
    ppt: 600,
    tablet: 1250,
    pc: Infinity
  },
  defaultBreakpoint: 'pc'
});
router.beforeEach(function (_ref, from, next) {
  var meta = _ref.meta,
      path = _ref.path,
      fullPath = _ref.fullPath;
  window.scrollTo(0, 0);
  document.title = meta.title || '拙诚教学系统';
  console.log(path + "-----" + fullPath + '-----' + meta);

  if (path === '/login') {
    next();
    return;
  } //设置水印


  Watermark.set(utils.currentUser().email); // 校验该路径的权限

  var realPath = path.replace("/", '');

  for (var per in constants.permission) {
    var item = constants.permission[per];

    if (item.path === undefined || item.path === null) {
      continue;
    } // 有该权限则不处理


    if (utils.checkPermission(item)) {
      continue;
    } // 如果相等，证明该path无权限，如果startsWith item.path，证明该path的父级没有权限


    if (realPath === item.path || realPath.startsWith(item.path)) {
      alert('抱歉，您该页面的权限已被关闭，请联系相关人员');
      next('/home');
      return;
    }
  }

  if (realPath == "workSystem/workSystem") {
    var mp_token = window.localStorage.getItem('mp_token');

    if (mp_token && !utils.isEmpty(mp_token)) {
      window.open(config.tsMpAppUrl + "/home?mp_token=" + mp_token, "_blank");
      next('/home');
    } else {
      window.vue.$confirm('由于您第一次使用作业小程序，请您重新登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        next('/login');
      }).catch(function () {});
    }

    return;
  }

  if (window.localStorage.getItem('token') || utils.checkNotNeedLogin(realPath)) {
    next();
  } else {
    next('/login');
  }
});
var vue = new Vue({
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});
vue.$mount('#app');
window.vue = vue;