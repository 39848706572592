import "core-js/modules/es6.string.fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "BottomInfo",
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    screen: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      showScreen: this.screen
    };
  },
  methods: {
    onMouseOver: function onMouseOver() {
      if (this.fixed) {
        return;
      }

      this.showScreen = true;
    },
    onMouseOut: function onMouseOut() {
      if (this.fixed) {
        return;
      }

      this.showScreen = false;
    }
  },
  computed: {
    ICPUrl: function ICPUrl() {
      return process.env.VUE_APP_ICP_URL;
    },
    ICPNumber: function ICPNumber() {
      return process.env.VUE_APP_ICP_NUM;
    },
    docUrl: function docUrl() {
      return process.env.VUE_APP_DOC_URL;
    },
    getVersion: function getVersion() {
      var versionCode = this.constants.version_code;
      if (!versionCode) return "";
      var low = versionCode % 1000;
      versionCode = Math.floor(versionCode / 1000);
      var m = versionCode % 1000;
      versionCode = Math.floor(versionCode / 1000);
      var high = versionCode % 1000;
      return "版本号: " + high + "." + m + "." + low;
    }
  }
};