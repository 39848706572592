export var all_permission = {
  teachManage: {
    name: '教研权限',
    key: 'p200',
    path: 'teachingResearch'
  },
  prepareLessonsCheck: {
    name: '备课授课权限',
    key: 'p201',
    path: 'teachingManage/prepareLessons'
  },
  taskBank: {
    name: '试题库',
    key: 'p202',
    path: 'sourceManage/taskSearch'
  },
  paperBank: {
    name: '试卷库',
    key: 'p203',
    path: 'sourceManage/paperList'
  },
  teachTaskAllot: {
    name: '教研任务分配',
    key: 'p400'
  },
  teachTaskCheck: {
    name: '教研任务审核',
    key: 'p401'
  },
  taskInput: {
    name: '试题录入',
    key: 'p500',
    path: 'sourceManage/taskInput'
  },
  taskCheck: {
    name: '试题审核',
    key: 'p501'
  },
  personalHandouts: {
    name: '个性化讲义权限',
    key: 'p600'
  },
  personalWare: {
    name: '个性化课件权限',
    key: 'p603'
  },
  ignorePrepareLessonsTest: {
    name: '跳过备课测试权限',
    key: 'p601'
  },
  ignoreBeforeClass: {
    name: '跳过课前考权限',
    key: 'p602'
  },
  paperCheck: {
    name: '试卷审核',
    key: 'p701'
  }
};