import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import { JSEncrypt } from 'jsencrypt';

var isEmpty = function isEmpty(result) {
  if (result == null || result === "" || result === 'undefined' || result === undefined) {
    return true;
  }

  if (Object.prototype.toString.call(result) === '[object Array]' && result.length === 0) {
    return true;
  }

  return false;
};

export var utils = {
  globalPermission: null,
  publicKey: "-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDSSZwUzkgCHUZse0+pIZfljxK+\nEefDwN7SBgt3FrSs/BfNuvbgJW+Ym1JrzyUmblbw+EmUMTi3ZlvLqkJNZvjGcorb\niN8+xKmbYP/wgLH3qrakAzF3goC2QTs6ocfyBE8uD8fKil7jZIoAiosmDvRflVe6\nWwpDQ394rxtXrWWGFQIDAQAB\n-----END PUBLIC KEY-----\n",
  isEmpty: isEmpty,
  toChinesNum: function toChinesNum(num) {
    var changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"

    var unit = ["", "十", "百", "千", "万"];
    num = parseInt(num);

    var getWan = function getWan(temp) {
      var strArr = temp.toString().split("").reverse();
      var newNum = "";

      for (var i = 0; i < strArr.length; i++) {
        newNum = (i === 0 && strArr[i] === 0 ? "" : i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? "" : changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i])) + newNum;
      }

      return newNum;
    };

    var overWan = Math.floor(num / 10000);
    var noWan = num % 10000;

    if (noWan.toString().length < 4) {
      noWan = "0" + noWan;
    }

    return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
  },
  dateFormat: function dateFormat(timestamp, formats) {
    // formats格式包括
    // 1. Y-m-d
    // 2. Y-m-d H:i:s
    // 3. Y年m月d日
    // 4. Y年m月d日 H时i分
    formats = formats || 'Y-m-d';

    var zero = function zero(value) {
      if (value < 10) {
        return '0' + value;
      }

      return value;
    };

    var myDate = timestamp ? new Date(timestamp) : new Date();
    var year = myDate.getFullYear();
    var month = zero(myDate.getMonth() + 1);
    var day = zero(myDate.getDate());
    var hour = zero(myDate.getHours());
    var minite = zero(myDate.getMinutes());
    var second = zero(myDate.getSeconds());
    return formats.replace(/Y|m|d|H|i|s/ig, function (matches) {
      return {
        Y: year,
        m: month,
        d: day,
        H: hour,
        i: minite,
        s: second
      }[matches];
    });
  },
  checkPermission: function checkPermission(prType) {
    var permission = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    if (permission === null || permission === undefined) {
      if (this.globalPermission === null) {
        this.globalPermission = JSON.parse(window.localStorage.getItem('permission'));
      }

      permission = this.globalPermission;
    } // 退出登录后权限会清空


    if (this.isEmpty(permission)) {
      return true;
    }

    return permission[prType.key];
  },
  popupDownload: function popupDownload(url, context) {
    context.$message({
      message: '准备下载试卷',
      type: 'success'
    });
    var link = document.createElement('a');
    link.href = url;
    link.download = url.substr(url.lastIndexOf('/') + 1);
    link.click();
  },
  // 加密
  encrypt: function encrypt(data) {
    if (isEmpty(data)) {
      return null;
    } // 加密内容不允许int型，只能是字符串


    data = data + ''; // 新建JSEncrypt对象

    var encryptor = new JSEncrypt(); // 设置公钥

    encryptor.setPublicKey(this.publicKey); // 加密数据

    return encryptor.encrypt(data);
  },
  replaceEnterToBr: function replaceEnterToBr(data) {
    return data.replace(/\n/g, '<br/>');
  },
  checkNotNeedLogin: function checkNotNeedLogin(path) {
    if (path.includes('paperOnlinePrepare') || path.includes('paperOnline')) {
      return true;
    }

    return false;
  },
  currentUser: function currentUser() {
    return {
      username: localStorage.getItem('user'),
      avatar: localStorage.getItem('avatar'),
      id: parseInt(localStorage.getItem('id')),
      email: localStorage.getItem('email')
    };
  },

  /**
   * Popup download dialog from Blob
   * @param blob
   * @param name
   */
  downloadFile: function downloadFile(blob) {
    var name = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'file.txt';
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    var blobUrl = URL.createObjectURL(blob); // Create a link element

    var link = document.createElement("a"); // Set link's href to point to the Blob URL

    link.href = blobUrl;
    link.download = name; // Append link to the body

    document.body.appendChild(link); // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox

    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })); // Remove link from body

    document.body.removeChild(link);
  }
};