import { utils } from "../../utils/utils";
var state = {
  sidebar: {
    opened: false,
    withoutAnimation: false,
    customLoading: true,
    user: localStorage.getItem('user'),
    avatar: localStorage.getItem('avatar') ? localStorage.getItem('avatar') : require("../../images/icon_default_portrait.png"),
    unread_events_count: 0
  },
  device: 'desktop',
  online_student_answer: []
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
  },
  OPEN_LOADING: function OPEN_LOADING(state) {
    state.sidebar.customLoading = true;
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  CLOSE_LOADING: function CLOSE_LOADING(state) {
    state.sidebar.customLoading = false;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  CHANGE_USER: function CHANGE_USER(state, userInfo) {
    state.sidebar.unread_events_count = userInfo.unread_events_count;
    state.sidebar.user = userInfo.username;
    state.sidebar.avatar = userInfo.avatar_url;
  },
  change_online_student_answer: function change_online_student_answer(state, answer) {
    if (utils.isEmpty(state.online_student_answer)) {
      state.online_student_answer = [];
    }

    var newAnswers = state.online_student_answer.filter(function (item) {
      return item.id != answer.id;
    });
    newAnswers.push(answer);
    state.online_student_answer = newAnswers;
  },
  init_online_student_answer: function init_online_student_answer(stage, answer_list) {
    state.online_student_answer = answer_list;
  }
};
var actions = {
  changeUser: function changeUser(_ref, userInfo) {
    var commit = _ref.commit;
    commit('CHANGE_USER', userInfo);
  },
  toggleSideBar: function toggleSideBar(_ref2) {
    var commit = _ref2.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref3, _ref4) {
    var commit = _ref3.commit;
    var withoutAnimation = _ref4.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  openLoading: function openLoading(_ref5) {
    var commit = _ref5.commit;
    commit('OPEN_LOADING');
  },
  closeLoading: function closeLoading(_ref6) {
    var commit = _ref6.commit;
    commit('CLOSE_LOADING');
  },
  toggleDevice: function toggleDevice(_ref7, device) {
    var commit = _ref7.commit;
    commit('TOGGLE_DEVICE', device);
  },
  changeOnlineStudentAnswer: function changeOnlineStudentAnswer(_ref8, answer) {
    var commit = _ref8.commit;
    commit('change_online_student_answer', answer);
  },
  initOnlineStudentAnswer: function initOnlineStudentAnswer(_ref9, answer_list) {
    var commit = _ref9.commit;
    commit('init_online_student_answer', answer_list);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};