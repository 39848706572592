import axios from 'axios';
import qs from 'qs';
import { utils } from "../utils/utils";
import { constants } from "../constants/constants"; // axios 配置

axios.defaults.timeout = 60000;
axios.defaults.withCredentials = true; //axios.defaults.baseURL = process.env.BASE_URL;
//POST传参序列化

var token_error_code = [40000, 40004, 40005, 40006];
axios.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
}); // 并且做权限处理

axios.interceptors.response.use(function (res) {
  // token过期
  var code = res.data.code;

  if (token_error_code.indexOf(code) !== -1) {
    alert(res.data.msg);
    window.localStorage.clear();
    window.location.href = "/login";
    return null;
  }

  if (res.data.permission) {
    utils.globalPermission = res.data.permission;
    window.localStorage.setItem('permission', JSON.stringify(res.data.permission));
    alert("您的权限已被修改，请及时刷新页面");
  }

  return res;
}, function (error) {
  // if(error&&error.response){
  //     switch (error.response.status) {
  //         case 401:
  //             Message.error('未登录！');
  //             break;
  //         case 403:
  //             Message.error('无权限！');
  //             break;
  //         case 404:
  //             //window.location.href = "/noPermission";
  //             break;
  //         default:
  //
  //     }
  // }
  return Promise.reject(error);
});
/**
 * http请求
 * @param url
 * @param params
 * @param method
 * @returns {Promise}
 */

export function fetch(url, params) {
  var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'get';
  var multipart = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  return new Promise(function (resolve, reject) {
    var axiosParams = {};
    var headers = {};

    if (window.localStorage.getItem('token')) {
      headers['token'] = window.localStorage.getItem('token');
      headers['mp_token'] = window.localStorage.getItem('mp_token');
    }

    url = url + "?time=" + new Date().getTime();

    if (method === 'GET' || method === 'get') {
      axiosParams = {
        method: 'get',
        url: url,
        headers: headers
      };

      if (JSON.stringify(params) !== "{}") {
        axiosParams.params = params;
      }
    } else if (multipart) {
      // better for files especially for large ones
      headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
      axiosParams = {
        method: method,
        url: url,
        headers: headers
      };
      var form = new FormData();

      for (var key in params) {
        form.append(key, params[key]);
      }

      axiosParams.data = form;
    } else {
      headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
      axiosParams = {
        method: method,
        url: url,
        headers: headers
      };
      params = qs.stringify(params);
      axiosParams.data = params;
    } //loading
    //发送请求


    axios(axiosParams).then(function (res) {
      if (utils.isEmpty(res) || utils.isEmpty(res.data)) {
        reject('返回数据为空');
        return;
      }

      if (res.data.code === constants.API_RESULT_OK) {
        resolve(res.data);
      } else {
        var error_message = utils.isEmpty(res.data.message) ? res.data.msg : res.data.message;
        reject({
          msg: error_message,
          code: res.data.code
        });
      }
    }).catch(function (error) {
      reject(error);
    });
  });
}