var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "flex-direction": "column" } },
    [
      _vm.showLogo
        ? _c("logo", {
            staticStyle: { "flex-shrink": "0" },
            attrs: { collapse: _vm.isCollapse }
          })
        : _vm._e(),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": "#304156",
                "text-color": "#bfcbd9",
                "unique-opened": true,
                "active-text-color": "#409EFF",
                "collapse-transition": false,
                mode: "vertical"
              }
            },
            _vm._l(_vm.routes, function(route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path }
              })
            }),
            1
          )
        ],
        1
      ),
      !_vm.isCollapse
        ? _c(
            "div",
            { staticClass: "bottom-info" },
            [_c("bottom-info", { attrs: { screen: false, fixed: true } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }