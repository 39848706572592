var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" }
      }),
      _c(
        "div",
        {
          staticClass: "right-menu",
          staticStyle: { "font-size": "12px", "margin-right": "5px" }
        },
        [
          _c("el-divider", { attrs: { direction: "vertical" } }),
          _c("i", {
            staticClass: "el-icon-switch-button",
            staticStyle: { "margin-left": "5px" }
          }),
          _c(
            "span",
            {
              staticStyle: { "margin-left": "5px", cursor: "pointer" },
              on: { click: _vm.logout }
            },
            [_vm._v("退出")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
            _c(
              "div",
              { staticClass: "right-menu-item-1" },
              [
                _c(
                  "el-badge",
                  {
                    key: _vm.sidebar.unread_events_count,
                    attrs: {
                      value: _vm.sidebar.unread_events_count,
                      max: 99,
                      hidden: _vm.sidebar.unread_events_count == 0
                    }
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-bell",
                        size: "mini",
                        type: "info",
                        circle: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.gotoMyEvents()
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "el-dropdown",
            {
              staticClass: "right-menu-item hover-effect",
              attrs: { trigger: "click" }
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("el-avatar", {
                    attrs: { size: 35, src: _vm.getAvatar() }
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "user-name",
                      staticStyle: { "font-size": "12px" }
                    },
                    [_vm._v(_vm._s(_vm.sidebar.user))]
                  ),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/userSet" } },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticStyle: { "white-space": "nowrap" },
                          attrs: { icon: "el-icon-s-tools" }
                        },
                        [_vm._v("修改信息")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/updatePassword" } },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          staticStyle: { "white-space": "nowrap" },
                          attrs: { icon: "el-icon-key" }
                        },
                        [_vm._v("修改密码")]
                      )
                    ],
                    1
                  ),
                  _c("el-dropdown-item", { attrs: { divided: "" } }),
                  _c(
                    "el-dropdown-item",
                    {
                      staticStyle: { "white-space": "nowrap" },
                      attrs: { icon: "el-icon-switch-button" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.logout($event)
                        }
                      }
                    },
                    [_vm._v("退出登录")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }