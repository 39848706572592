import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export default new Router({
  mode: 'history',
  routes: [
  /**首页**/
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'home',
      component: function component() {
        return import('@/views/home/index.vue');
      },
      meta: {
        title: '首页',
        icon: 'el-icon-s-home'
      }
    }]
  },
  /**教研管理部分**/
  {
    path: '/teachingResearch',
    component: Layout,
    redirect: '/teachingResearch/handouts',
    alwaysShow: true,
    name: 'teachingResearch',
    meta: {
      title: '教研管理',
      icon: 'el-icon-edit-outline'
    },
    children: [{
      path: 'handouts',
      name: 'handouts',
      component: function component() {
        return import('@/views/teachingResearch/handouts/index.vue');
      },
      meta: {
        title: '讲义',
        icon: 'el-icon-reading'
      }
    }, {
      path: 'courseware',
      name: 'courseware',
      component: function component() {
        return import('@/views/teachingResearch/courseware/index.vue');
      },
      meta: {
        title: '课件',
        icon: 'el-icon-data-board'
      }
    }, // 讲义详情
    {
      path: '/handoutsDetail',
      name: 'handoutsDetail',
      hidden: true,
      component: function component() {
        return import('@/views/teachingResearch/handoutsDetail/index.vue');
      }
    }, // 课件详情
    {
      path: '/coursewareDetail',
      name: 'coursewareDetail',
      hidden: true,
      component: function component() {
        return import('@/views/teachingResearch/coursewareDetail/index.vue');
      }
    }, // 任务分配页面
    {
      path: '/courseHandouts',
      name: 'courseHandouts',
      hidden: true,
      component: function component() {
        return import('@/views/teachingResearch/courseHandouts/index.vue');
      }
    }, // word转html工具
    {
      path: '/wordToHtml',
      name: 'wordToHtml',
      hidden: true,
      component: function component() {
        return import('@/views/teachingResearch/wordToHtml/index.vue');
      }
    }]
  },
  /**教学管理部分**/
  {
    path: '/teachingManage',
    component: Layout,
    redirect: '/teachingManage/prepareLessons',
    alwaysShow: true,
    name: 'teachingManage',
    meta: {
      title: '教学管理',
      icon: 'el-icon-school'
    },
    children: [{
      path: '/prepareLessons',
      name: 'prepareLessons',
      component: function component() {
        return import('@/views/teachingManage/prepareLessons/index.vue');
      },
      meta: {
        title: '备课',
        icon: 'el-icon-s-flag'
      }
    }, {
      path: '/prepareLessonDetail',
      name: 'prepareLessonDetail',
      component: function component() {
        return import('@/views/teachingManage/prepareLessonDetail/index.vue');
      }
    }, {
      path: '/goLesson',
      name: 'goLesson',
      component: function component() {
        return import('@/views/teachingManage/goLesson/index.vue');
      }
    }, {
      path: 'lessonlist',
      name: 'lessonlist',
      component: function component() {
        return import('@/views/teachingManage/lessonlist/index.vue');
      },
      meta: {
        title: '上课记录',
        icon: 'el-icon-date'
      }
    }, {
      path: 'consideration',
      name: 'consideration',
      component: function component() {
        return import('@/views/teachingManage/consideration/index.vue');
      },
      meta: {
        title: '反思列表',
        icon: 'el-icon-tickets'
      }
    }, {
      path: '/considerationDetail',
      name: 'considerationDetail',
      hidden: true,
      component: function component() {
        return import('@/views/teachingManage/considerationDetail/index.vue');
      }
    }, {
      path: '/considerationDetailWrite',
      name: 'considerationDetailWrite',
      hidden: true,
      component: function component() {
        return import('@/views/teachingManage/considerationDetailWrite/index.vue');
      }
    }]
  }, {
    path: '/sourceManage/paperOnline',
    name: 'paperOnline',
    component: function component() {
      return import('@/module/components/paper/PaperOnline');
    }
  },
  /**资料库**/
  {
    path: '/sourceManage',
    component: Layout,
    redirect: '/sourceManage/database',
    alwaysShow: true,
    name: 'sourceManage',
    meta: {
      title: '资源管理',
      icon: 'el-icon-receiving'
    },
    children: [// {
    //   path: 'handoutsBank',
    //   name: 'handoutsBank',
    //   component: () => import('@/views/teachingManage/prepareLessons/index.vue'),
    //   meta: {title: '讲义库', icon: 'el-icon-document-copy'}
    // },
    {
      path: 'database',
      name: 'database',
      component: function component() {
        return import('@/views/sourceManage/database/index.vue');
      },
      meta: {
        title: '资料库',
        icon: 'el-icon-files'
      }
    }, // 资料编辑
    {
      path: '/materialEdit',
      name: 'materialEdit',
      hidden: true,
      component: function component() {
        return import('@/views/sourceManage/editMaterial/index.vue');
      }
    }, {
      path: 'taskSearch',
      name: 'taskSearch',
      component: function component() {
        return import('@/views/sourceManage/taskSearch/TaskSearch');
      },
      meta: {
        title: '试题库',
        icon: 'el-icon-notebook-1'
      }
    }, {
      path: 'taskInput',
      name: 'taskInput',
      component: function component() {
        return import('@/views/sourceManage/taskInput/index.vue');
      },
      meta: {
        title: '试题录入',
        icon: 'el-icon-edit'
      }
    }, {
      path: 'taskInputForPrepareTest',
      name: 'taskInputForPrepareTest',
      component: function component() {
        return import('@/views/sourceManage/taskInput/taskInputForPrepareTest');
      },
      meta: {
        title: '备课测试题录入',
        icon: 'el-icon-edit'
      }
    }, {
      path: 'paperList',
      name: 'paperList',
      component: function component() {
        return import('@/views/sourceManage/paperList/PaperList.vue');
      },
      meta: {
        title: '试卷库',
        icon: 'el-icon-document'
      }
    }, {
      path: 'taskComposition',
      name: 'taskComposition',
      component: function component() {
        return import('@/views/sourceManage/taskComposition/TaskComposition');
      }
    }, {
      path: 'paperOnlineList',
      name: 'paperOnlineList',
      component: function component() {
        return import('@/views/sourceManage/paperOnline/PaperOnlineList');
      },
      meta: {
        title: '在线卷',
        icon: 'el-icon-document'
      }
    }, {
      path: 'paperOnlinePrepare',
      name: 'paperOnlinePrepare',
      component: function component() {
        return import('@/module/components/paper/PaperOnlinePrepare');
      } // meta: {title: '在线卷', icon: 'el-icon-document'}

    }, {
      path: 'paperOnlineCorrect',
      name: 'paperOnlineCorrect',
      component: function component() {
        return import('@/module/components/paper/PaperOnlineCorrect');
      } // meta: {title: '在线卷', icon: 'el-icon-document'}

    }]
  }, {
    path: '/play',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '师训设置',
      icon: 'el-icon-video-camera'
    },
    children: [{
      path: 'admin/sx/list',
      name: 'admin-sx-list',
      component: function component() {
        return import('@/views/admin/sx/index.vue');
      },
      meta: {
        title: '师训配置',
        icon: 'el-icon-s-tools'
      }
    }, {
      path: 'sx/list',
      name: 'sx-list',
      component: function component() {
        return import('@/views/sx/index.vue');
      },
      meta: {
        title: '师训列表',
        icon: 'el-icon-s-grid'
      }
    }, {
      path: 'sx/report-list',
      name: 'sx-report-list',
      component: function component() {
        return import('@/views/sx-report/index.vue');
      },
      meta: {
        title: '培训列表',
        icon: 'el-icon-s-data'
      }
    }]
  },
  /**数据统计**/
  {
    path: '/statistics',
    component: Layout,
    redirect: '/statistics/statistics',
    alwaysShow: true,
    name: 'statistics',
    meta: {
      title: '数据统计',
      icon: 'el-icon-data-line'
    },
    children: [{
      path: 'statistics',
      name: 'statisticsHome',
      component: function component() {
        return import('@/views/statistics/statisticsHome/StatisticsHome');
      },
      meta: {
        title: '数据统计',
        icon: 'el-icon-pie-chart'
      }
    }]
  },
  /**用户设置**/
  {
    path: '/userSet',
    component: Layout,
    redirect: '/userSet/user',
    alwaysShow: true,
    name: 'userSet',
    meta: {
      title: '用户设置',
      icon: 'el-icon-user'
    },
    children: [{
      path: 'user',
      name: 'user',
      component: function component() {
        return import('@/views/userSet/index.vue');
      },
      meta: {
        title: '修改信息',
        icon: 'el-icon-s-tools'
      }
    }, {
      path: '/updatePassword',
      name: 'updatePassword',
      component: function component() {
        return import('@/views/updatePassword/index.vue');
      },
      meta: {
        title: '修改密码',
        icon: 'el-icon-key'
      }
    }, {
      path: '/myEvents',
      name: 'myEvents',
      component: function component() {
        return import('@/views/userEvents/index.vue');
      },
      meta: {
        title: '事件提醒',
        icon: 'el-icon-bell'
      }
    }]
  },
  /**登录页面**/
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: function component() {
      return import('@/views/login/login.vue');
    }
  }, {
    path: '/workSystem',
    component: Layout,
    redirect: '/workSystem',
    children: [{
      path: 'workSystem',
      name: 'workSystem',
      component: function component() {
        return import('@/views/workSystem/index.vue');
      },
      meta: {
        title: '作业系统',
        icon: 'el-icon-pie-chart'
      }
    }]
  }]
});